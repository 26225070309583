<template>
	<div class="input-wrapper" v-show="true">
		<label :for="'webinars-filter-input-' + id" class="label__input">{{ title }}</label>
		<input type="text" name="webinars-filter" :id="'webinars-filter-input-' + id" list="webinars-filter" class="select input__slim" :placeholder="placeholder" v-model="search_req" @click="toggleList" @input="show_list = true">
		<span class="expand-icon"></span>
			<fieldset  class="webinars-filter" v-if="show_list" @keyup.esc="show_list = false">
				<div class="datalist-checkbox-wrapper" v-for="item, key in filteredItems" :key="'business-type-'+key">
					<input type="checkbox" :id="'item-n-'+key"  :value="item.title || item.name || item.type" class="visually-hidden" v-model="selected_names">
					<label :for="'item-n-'+key" @click="search_req = null">{{ item.title || item.name || item.type }}</label>
				</div>
			</fieldset>
		<ul class="filters-wrapper" v-show="true">
			<li class="link__btn btn__filter btn__filter-name" v-for="s_item, key in selected_names" :key="'selected-item-'+key">
				<p>{{ s_item }}</p>
				<button type="button" class="btn__close" @click="removeItemFromList(s_item)"></button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'MultipleSelect',
	data() {
		return {
			search_req: null,
			selected_names: [],
			show_list: false,
		}
	},
	props: {
		id: {
			type: [String, Number],
			default: 1
		},
		items: {
			type: Array,
			required: true,
		},
		selectedItems: {
			type: Array,
			required: false,
			validate: (v) => v != undefined
		},
		title: {
			type: String,
			default: 'Фильтр',
		},
		placeholder: {
			type: String,
			default: 'Начните ввод для поиска по списку'
		},
		cleanOnReset: {
			type: Boolean,
			default: false,
		}
	},
	watch: {
		selected_names(names) {
			this.$emit('select', names)
		},
		cleanOnReset(value) {
			if(value === true) this.selected_names = []
		}
	},
	computed: {
		filteredItems() {
			const items = this.items
			return this.search_req != null ? items.filter(item => item.title.toLowerCase().includes(this.search_req.toLowerCase())) : items
		},
	},
	methods: {
		removeItemFromList(s_item) {
			this.selected_names = this.selected_names.filter(item => item != s_item)
		},
		toggleList() {
			this.show_list = this.show_list ? false : true
		},
	},
	mounted() {
		this.selected_names = this.selectedItems ? this.selectedItems : []
	}

}
</script>

<style scoped>
	.input-wrapper {
		position: relative;
	}

	.datalist-checkbox-wrapper input:focus-visible + label {
		outline: 2px solid var(--dark-blue);
		outline-offset: -3px;
	}

	.datalist-checkbox-wrapper input:checked + label {
		background-color: var(--light-blue);
		color: #ffffff;
	}

	.datalist-checkbox-wrapper {
		display: block;
		width: 100%;
	}

	.datalist-checkbox-wrapper label {
		display: block;
		width: 100%;
		padding: 5px 10px;
	}

	.webinars-filter {
		padding: 0; margin: 0 5px 0 5px;
		display: flex;
		position: absolute;
		background-color: #ffffff;
		flex-direction: column;
		justify-content: flex-start;
		align-content: stretch;
		border: none;
		border-radius: 0 0 9px 9px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		max-height: 250px;
		overflow-y: scroll;
		width: 100%;
	}

	.webinars-filter:first-of-type {
		z-index: 1;
	}

	.filters-wrapper {
		margin-top: 10px;
	}

</style>
