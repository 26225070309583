<template>
	<div class="input-wrapper file-input-wrapper">
		<label
			for="q_file"
			class="link__btn btn__white"
			:class="[{'btn__blue': selectedFileNames.length > 0, 'btn__disabled': disabled}]"
		>
			<input
				ref="fileinput"
				id="q_file"
				type="file"
				name="q_file"
				class="visually-hidden"
				:multiple="multiple"
				:accept="acceptTypes"
				:disabled="disabled"
				:required="required"
				@input="selectFiles"
				aria-label="Подгрузить файлы"
			>
			{{ name }}
		</label>
		<ul class="q-file-list">
			<li v-for="(name, key) in selectedFileNames" :key="'file-name-'+key">{{ name }}</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'InputFile',
	data() {
		return {
			selectedFileNames: [],
			mimetypes: [
				'image/gif',
				'image/jpeg',
				'image/png',
				'image/webp',
				'text/csv',
				'text/plain',
				'application/msword',
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'application/pdf',
				'application/vnd.ms-powerpoint',
				'application/vnd.openxmlformats-officedocument.presentationml.presentation',
				'application/vnd.ms-excel',
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			],
		}
	},
	props: {
		disabled: {
			type: Boolean,
			default: false
		},
		multiple: {
			type: Boolean,
			default: true,
		},
		name: {
			type: String,
			default: 'Подгрузить файлы'
		},
		files: {
			type: [Array, FileList],
			required: false
		},
		required: {
			type: Boolean,
			default: false,
		}
	},
	watch: {
		files(value) {
			if (value.length === 0) {
				this.reset()
			}
		}
	},
	computed: {
		acceptTypes() {
			let types = ''
			for (let i in this.mimetypes) {
				types+= i < this.mimetypes.length - 1 ? this.mimetypes[i] + ', ' : this.mimetypes[i]
			}
			return types
		}
	},
	methods: {
		selectFiles(e) {
			const files = e.target.files
			if (files.length > 0) {
				this.selectedFileNames = Array.from(files).map((file) => {
					return file.name
				})
			}
			this.$emit('input', files)
		},
		reset() {
			this.$refs.fileinput.value = []
			this.selectedFileNames = []
		}
	},
}
</script>

<style>

</style>
